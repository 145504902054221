import { template as template_1dec87c909424d4e8f5718f8eb0df3bd } from "@ember/template-compiler";
const FKText = template_1dec87c909424d4e8f5718f8eb0df3bd(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
