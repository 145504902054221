import { template as template_8c9654c0a513440a8ff6212612dd44e2 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_8c9654c0a513440a8ff6212612dd44e2(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
