import { template as template_b04a0d9995b9487faf9ed9ae173b67ce } from "@ember/template-compiler";
const FKControlMenuContainer = template_b04a0d9995b9487faf9ed9ae173b67ce(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
